<template>
  <main class="main sc">
    <div class="container">
      <div
        class="sc__map"
        :class="{
          'sc__map--crosshair': mapState === 'map:reporting',
        }"
      >
        <ClientOnly v-if="mapReady">
          <YandexMapComponent
            v-if="activeService === 0"
            :satellite="showSatellite"
            :zoom="zoom"
            :center="center"
            :bounds="bounds"
            :mapState="mapState"
            :targetMarker="targetMarker"
            :categorySelected="categorySelected"
            :statusSelected="statusSelected"
            @zoom="zoomChanged"
            @click="setMarkerWaitingState"
            @centerChange="centerChanged"
            @markerClick="setMarkerReadState"
            @mouseenter="showMarkerHint"
            @mouseleave="hideMarkerHint"
          />
        </ClientOnly>
        <MarkerCategories v-if="mapState === 'map:default'" :menuShow="menuShow" @change="categoryChanged" />
        <MapActionLabel message="Укажите место на карте" v-if="mapState === 'map:reporting'" />
        <MapMarkerFilters
          v-if="['marker:waiting', 'map:reporting', 'map:default'].includes(mapState)"
          :mapState="mapState"
          :msShow="msShow"
          @change="statusChanged"
          @reportState="setReportState"
          @defaultState="setDefaultState"
        />
        <MapTypeControls
          v-if="['map:reporting', 'map:default'].includes(mapState)"
          :activeService="activeService"
          :showSatellite="showSatellite"
          @setService="setService"
          @incZoom="zoom += 1"
          @decZoom="zoom -= 1"
        />
        <transition name="slide-below">
          <ClaimHintModal
            @mouseenter="showMarkerHint(activeMarker)"
            @mouseleave="hideMarkerHint"
            v-if="showHint"
            :marker="activeMarker"
            @click="setMarkerReadState"
          />
        </transition>
        <MapMenu
          :mapState="mapState"
          :msShow="msShow"
          :menuShow="menuShow"
          @changeMs="changeMs"
          @reportState="setReportState"
          @defaultState="setDefaultState"
          @changeMenu="changeMenu"
        />
      </div>
    </div>
  </main>
</template>

<script>
import ClientOnly from "vue-client-only";
import { loadYmap } from "vue-yandex-maps";
import MapTypeControls from "./components/controls/MapTypeControls.vue";
import MarkerCategories from "./components/controls/MarkerCategories.vue";
import MapMarkerFilters from "./components/controls/MapMarkerFilters.vue";
import ClaimHintModal from "./components/controls/ClaimHint.vue";
import MapActionLabel from "./components/controls/MapActionLabel.vue";
import LoginModal from "components/modals/LoginModal.vue";
import ClaimModal from "./components/modals/ClaimModal.vue";
import NewClaimModal from "./components/modals/NewClaimModal.vue";
import MapMenu from "./components/controls/MapMenu.vue";
import AlertModal from "./components/modals/AlertModal.vue";

// map:default = Просто карта
// map:reporting = Карта ожидает нажатия
// marker:read = Нажали на маркер и смотрят информацию
// marker:waiting = Маркер поставлен и заполняется сообщение о проблеме в модалке
export default {
  name: "SocialControlMapPage",
  data() {
    return {
      msShow: false,
      menuShow: false,
      categorySelected: [],
      statusSelected: [],
      activeMarker: null,
      targetMarker: null,
      activeService: 0,
      zoom: 10,
      defaultZoom: 13,
      center: [0, 0],
      bounds: null,
      defaultCenter: [0, 0],
      mapState: "map:default",
      showSatellite: false,
      hideHintTimeout: null,
      zoomDebounce: null,
      mapReady: false,
      showHint: false,
      settings: {
        apiKey: "2accbdff-2038-4811-9f12-f3799d64a50c",
        lang: "ru_RU",
        coordorder: "latlong",
        version: "2.1",
      },
    };
  },
  async beforeMount() {
    if (localStorage.getItem("apollo-token")) {
      await this.$store.dispatch("SC_PAGE", this.$apollo.provider).catch(({ graphQLErrors }) => {
        this.$store.state._modals = [
          {
            component: AlertModal,
            options: {
              message: graphQLErrors[0].message,
              callback: () => {
                this.$store.state._modals = [
                  {
                    component: AlertModal,
                  },
                ];
              },
            },
          },
        ];
      });
      loadYmap({
        ...this.settings,
      }).then(() => {
        let markers = JSON.parse(JSON.stringify(this.markers)).map((marker) => marker.coords);
        if (markers.length) {
          let minX = markers[0][0];
          let minY = markers[0][1];
          let maxX = markers[0][0];
          let maxY = markers[0][1];
          markers.forEach((marker) => {
            if (marker[0] > maxX) maxX = marker[0];
            if (marker[1] > maxY) maxY = marker[1];
            if (marker[0] < minX) minX = marker[0];
            if (marker[1] < minY) minX = marker[1];
          });
          this.bounds = [
            [minX, minY],
            [maxX, maxY],
          ];
        }
        // window.ymaps.ready(() => {
        //   window.ymaps.geocode("Россия, Республика Дагестан, Махачкала").then((res) => {
        this.centerChanged([42.967631, 47.503442]);
        // this.centerChanged(res.geoObjects.get(0).geometry.getCoordinates());
        this.mapReady = true;
        // });
        // });
      });
    }
  },
  mounted() {
    if (!localStorage.getItem("apollo-token")) {
      this.$store.state._modals = [
        {
          component: LoginModal,
        },
      ];
    }
  },
  computed: {
    markers() {
      return this.$store.getters.socialControlMarkers(this.statusSelected, this.categorySelected);
    },
  },
  methods: {
    changeMs() {
      this.msShow = !this.msShow;
      if (this.msShow) this.menuShow = false;
    },
    changeMenu() {
      this.menuShow = !this.menuShow;
      if (this.menuShow) this.msShow = false;
    },
    showMarkerHint(e) {
      clearTimeout(this.hideHintTimeout);
      if (this.mapState === "map:default") {
        this.showHint = true;
        this.activeMarker = e;
      }
    },
    hideMarkerHint() {
      clearTimeout(this.hideHintTimeout);
      this.hideHintTimeout = setTimeout(() => {
        this.showHint = false;
      }, 100);
    },
    setMarkerReadState(e) {
      if (["map:default", "marker:read"].includes(this.mapState)) {
        this.activeMarker = e;
        this.$store.state._modals.push({
          component: ClaimModal,
          options: {
            marker: e,
          },
        });
        this.hideMarkerHint();
      }
    },
    setMarkerWaitingState(e) {
      if (this.mapState === "map:reporting") {
        this.targetMarker = e;
        this.$store.state._modals.push({
          component: NewClaimModal,
          options: {
            marker: e,
            noBackClose: true,
            callback: () => {
              this.setDefaultState();
            },
          },
        });
        this.mapState = "marker:waiting";
        this.hideMarkerHint();
      }
    },
    setReportState() {
      this.mapState = "map:reporting";
      this.hideMarkerHint();
    },
    setDefaultState() {
      this.mapState = "map:default";
      this.hideMarkerHint();
    },
    statusChanged(selected) {
      this.statusSelected = selected;
    },
    centerChanged(e) {
      this.center = e;
      if (this.mapState === "map:default") {
        this.defaultCenter = e;
      }
    },
    zoomChanged(e) {
      this.zoom = e;
      //   if (this.mapState === "map:default") {
      //     this.defaultZoom = e;
      //   }
    },
    categoryChanged(selected) {
      this.categorySelected = selected;
    },
    setService(event) {
      // Если надо поменять на яндекс - меняю на 2гис и потом меняю на яндекс
      // потому что яндекс не видел обновления свойства showSatellite
      if (event.service === 0) {
        this.activeService = 1;
        this.$nextTick(() => {
          this.activeService = event.service;
          this.showSatellite = event.satellite;
        });
        return;
      }
      this.activeService = event.service;
    },
  },
  metaInfo() {
    return this.$seo("common", "Общественный контроль", "", "", "Общественный контроль", "", "");
  },
  components: {
    MapActionLabel,
    ClaimHintModal,
    MapMarkerFilters,
    MarkerCategories,
    MapTypeControls,
    MapMenu,
    YandexMapComponent: () => import("./components/YandexMap.vue"),
    ClientOnly,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/parts/map-modal.styl"
@import "~@/styles/parts/map-ms.styl"
@import "~@/styles/parts/sc.styl"
</style>
