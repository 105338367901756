<template>
  <label
    class="mod-input"
    :class="{
      'mod-input--error': errorMessage,
      'no-print': noPrint,
    }"
  >
    <span class="mod-input__title">
      <span class="mod-input__required-star" v-if="required">*</span>
      <span> {{ title }}</span>
    </span>
    <div class="mod-input__container">
      <textarea
        :placeholder="placeholder"
        v-model="inputValue"
        class="mod-input__field"
        :rows="rows"
      ></textarea>
      <span class="mod-input__error" role="alert" v-if="errorMessage">{{ errorMessage }}</span>
    </div>
  </label>
</template>

<script>
export default {
  name: "ModalTextComponent",
  props: {
    noPrint: Boolean,
    title: String,
    placeholder: {
      type: String,
      default: "",
    },
    rows: {
      type: Number,
      default: 5,
    },
    required: {
      type: Boolean,
      default: true,
    },
    value: [Number, String, Object],
    errorMessage: String,
  },
  data() {
    return {
      inputValue: this.value,
    };
  },
  watch: {
    inputValue(e) {
      this.$emit("input", e);
    },
  },
};
</script>
