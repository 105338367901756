<template>
  <div class="sc__map-ui map__menu-container">
    <div class="map__menu">
      <a @click.prevent="changeMs" :class="{ active: msShow }" class="map__menu-btn" href="#">
        Мои <br />
        сообщения
      </a>
      <div
        v-if="['marker:waiting', 'map:reporting', 'map:default'].includes(mapState)"
        class="map__menu-report"
      >
        <a @click.prevent="$emit(mapState !== 'map:default' ? 'defaultState' : 'reportState')" href="#">
          {{ mapState !== "map:default" ? "Отменить" : "Сообщить о проблеме" }}
        </a>
      </div>
      <a @click.prevent="changeMenu" :class="{ active: menuShow }" class="map__menu-btn" href="#">
        <span>Меню</span>
        <MenuIcon />
      </a>
    </div>
    <div :class="{ active: menuShow }" class="map__menu-nav map-ms--open">
      <router-link
        class="map__menu-link"
        v-for="(item, index) in menuLinks"
        :key="index"
        :to="{ name: item.route }"
      >
        {{ item.title }}
      </router-link>
    </div>
  </div>
</template>

<script>
import MenuIcon from "components/svg/MenuIcon.vue";

export default {
  name: "MapMenu",
  props: {
    mapState: String,
    menuShow: Boolean,
    msShow: Boolean,
  },
  data() {
    return {
      menuLinks: [
        {
          title: "О проекте",
          route: "sc_about",
        },
        {
          title: "Темы",
          route: "sc_themes",
        },
        {
          title: "Результаты",
          route: "sc_results",
        },
      ],
    };
  },
  methods: {
    changeMs() {
      this.$emit("changeMs");
    },
    changeMenu() {
      this.$emit("changeMenu");
    },
  },
  components: {
    MenuIcon,
  },
};
</script>
