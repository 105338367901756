<template>
  <figure class="icon">
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.5003 10.0003C17.2793 10.0003 17.0674 10.0881 16.9111 10.2444C16.7548 10.4006 16.667 10.6126 16.667 10.8336V15.8336C16.667 16.0546 16.5792 16.2666 16.4229 16.4229C16.2666 16.5791 16.0547 16.6669 15.8337 16.6669H4.16699C3.94598 16.6669 3.73402 16.5791 3.57774 16.4229C3.42146 16.2666 3.33366 16.0546 3.33366 15.8336V4.16694C3.33366 3.94593 3.42146 3.73397 3.57774 3.57769C3.73402 3.42141 3.94598 3.33361 4.16699 3.33361H9.16699C9.38801 3.33361 9.59997 3.24581 9.75625 3.08953C9.91253 2.93325 10.0003 2.72129 10.0003 2.50027C10.0003 2.27926 9.91253 2.0673 9.75625 1.91102C9.59997 1.75474 9.38801 1.66694 9.16699 1.66694H4.16699C3.50395 1.66694 2.86807 1.93033 2.39923 2.39917C1.93038 2.86802 1.66699 3.5039 1.66699 4.16694V15.8336C1.66699 16.4966 1.93038 17.1325 2.39923 17.6014C2.86807 18.0702 3.50395 18.3336 4.16699 18.3336H15.8337C16.4967 18.3336 17.1326 18.0702 17.6014 17.6014C18.0703 17.1325 18.3337 16.4966 18.3337 15.8336V10.8336C18.3337 10.6126 18.2459 10.4006 18.0896 10.2444C17.9333 10.0881 17.7213 10.0003 17.5003 10.0003ZM5.00033 10.6336V14.1669C5.00033 14.388 5.08812 14.5999 5.2444 14.7562C5.40068 14.9125 5.61265 15.0003 5.83366 15.0003H9.36699C9.47666 15.0009 9.58538 14.9799 9.68691 14.9384C9.78845 14.8969 9.88079 14.8358 9.95866 14.7586L15.7253 8.98361L18.092 6.66694C18.1701 6.58947 18.2321 6.4973 18.2744 6.39576C18.3167 6.29421 18.3385 6.18528 18.3385 6.07527C18.3385 5.96526 18.3167 5.85634 18.2744 5.75479C18.2321 5.65324 18.1701 5.56108 18.092 5.48361L14.5587 1.90861C14.4812 1.8305 14.389 1.76851 14.2875 1.7262C14.1859 1.68389 14.077 1.66211 13.967 1.66211C13.857 1.66211 13.7481 1.68389 13.6465 1.7262C13.545 1.76851 13.4528 1.8305 13.3753 1.90861L11.0253 4.26694L5.24199 10.0419C5.16476 10.1198 5.10365 10.2122 5.06218 10.3137C5.02071 10.4152 4.99969 10.5239 5.00033 10.6336ZM13.967 3.67527L16.3253 6.03361L15.142 7.21694L12.7837 4.85861L13.967 3.67527ZM6.66699 10.9753L11.6087 6.03361L13.967 8.39194L9.02533 13.3336H6.66699V10.9753Z"
        fill="white"
      />
    </svg>
  </figure>
</template>

<script>
export default {
  name: "EditIcon",
};
</script>
