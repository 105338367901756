<template>
  <figure class="icon">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.9997 13.4108L12.7097 9.1708C12.6167 9.07707 12.5061 9.00268 12.3843 8.95191C12.2624 8.90114 12.1317 8.875 11.9997 8.875C11.8677 8.875 11.737 8.90114 11.6151 8.95191C11.4933 9.00268 11.3827 9.07707 11.2897 9.1708L7.0497 13.4108C6.95598 13.5038 6.88158 13.6144 6.83081 13.7362C6.78004 13.8581 6.75391 13.9888 6.75391 14.1208C6.75391 14.2528 6.78004 14.3835 6.83081 14.5054C6.88158 14.6272 6.95598 14.7378 7.0497 14.8308C7.23707 15.017 7.49052 15.1216 7.7547 15.1216C8.01889 15.1216 8.27234 15.017 8.4597 14.8308L11.9997 11.2908L15.5397 14.8308C15.726 15.0155 15.9774 15.1197 16.2397 15.1208C16.3713 15.1216 16.5018 15.0963 16.6236 15.0466C16.7454 14.9968 16.8563 14.9235 16.9497 14.8308C17.0468 14.7412 17.1251 14.6332 17.1802 14.5131C17.2353 14.3931 17.2661 14.2633 17.2708 14.1312C17.2754 13.9992 17.2539 13.8675 17.2073 13.7439C17.1608 13.6202 17.0902 13.507 16.9997 13.4108Z"
        fill="white"
      />
    </svg>
  </figure>
</template>

<script>
export default {
  name: "UpperArrowIcon",
};
</script>
