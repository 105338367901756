<template>
  <figure class="icon">
    <svg
      width="24"
      height="24"
      viewBox="0 0 4775.7 4775.7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        fill="#A3C626"
        fill-rule="nonzero"
        d="M4775.84 2387.87c0,-293.75 -55.66,-573.93 -152.69,-833.81 -25.67,855.4 -895.11,1517.68 -1405.56,1955.95l1277.69 0c178.6,-334.64 280.56,-716.41 280.56,-1122.14zm-923.67 -1883.51c-404.54,-314.96 -911.76,-504.36 -1464.2,-504.36 -1318.83,0 -2387.97,1069.14 -2387.97,2387.87 0,1318.72 1069.14,2387.83 2387.97,2387.83 603.91,0 1153.8,-225.95 1574.32,-595.72l-1728.81 0 -5.27 -507.69c1041.67,-905.55 1680.23,-1517.96 1680.23,-2109.49 0,-219.84 -83.74,-497.26 -460.58,-497.26 -298.4,0 -586.26,172.85 -533.94,895.11l-607.14 0c-151.83,-837.42 303.57,-1502.25 1172.4,-1502.25 134.7,0 259.3,16.08 372.99,45.96z"
      />
    </svg>
  </figure>
</template>

<script>
export default {
  name: "TwoGisIcon",
};
</script>
