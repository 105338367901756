<template>
  <div class="sc__map-ui map-ms" :class="{ 'map-ms--open': open }" v-if="categories.length">
    <label class="map-ms__title">
      <input type="checkbox" v-model="open" v-show="false" />
      <span>Категории</span>
      <UpperArrowIcon v-if="open" />
      <DownArrowIcon v-else />
    </label>
    <div class="map-ms__list">
      <label class="map-ms__item map-ms__item--no-icon">
        <input type="checkbox" @change="toggleAll" v-show="false" />
        <span class="map-ms__check">
          <CheckIcon v-if="selected.length === categories.length" />
        </span>
        <span class="map-ms__item-title">Выбрать все</span>
      </label>
      <label class="map-ms__item" v-for="(c, i) in categories" :key="i">
        <input
          type="checkbox"
          @change="$emit('change', selected)"
          v-model="selected"
          :value="c.id"
          v-show="false"
        />
        <span class="map-ms__check">
          <CheckIcon v-if="selected.includes(c.id)" />
        </span>
        <i class="icon" v-html="c.icon"></i>
        <span class="map-ms__item-title">{{ c.title }}</span>
        <span class="map-ms__item-count">
          {{ c.childCategories.reduce((a, b) => a + b.claims_count || 0, 0) }}
        </span>
      </label>
    </div>
  </div>
</template>

<script>
import UpperArrowIcon from "components/svg/UpperArrow.vue";
import DownArrowIcon from "components/svg/DownArrow.vue";
import CheckIcon from "components/svg/CheckIcon.vue";

export default {
  name: "MapMultiselect",
  props: {
    menuShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      open: false,
      selected: [],
      psOptions: {
        wheelPropagation: false,
      },
    };
  },
  computed: {
    categories() {
      return this.$store.getters.socialControlCategories;
    },
  },
  watch: {
    categories() {
      if (!this.open) {
        this.selectAll();
      }
    },
  },
  methods: {
    toggleAll() {
      if (this.selected.length === this.categories.length) {
        this.selected = [];
        this.$emit("change", this.selected);
        return;
      }
      this.selectAll();
    },
    selectAll() {
      this.selected = this.categories.map((c) => c.id);
      this.$emit("change", this.selected);
    },
  },
  components: { CheckIcon, DownArrowIcon, UpperArrowIcon },
};
</script>
