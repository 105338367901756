<template>
  <figure class="icon">
    <svg width="11" height="9" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 4L4 7L10 1" stroke="#02548A" stroke-width="2" />
    </svg>
  </figure>
</template>

<script>
export default {
  name: "CheckIcon",
};
</script>
