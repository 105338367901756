<template>
  <div
    class="mod-input"
    :class="{
      'mod-input--error': errorMessage,
      'no-print': noPrint,
    }"
  >
    <span class="mod-field__title" v-if="disabled">{{ title }}</span>
    <span class="mod-input__title" v-else>
      <span class="mod-input__required-star" v-if="required">*</span>
      <span> {{ title }}</span>
    </span>
    <div class="mod-input__container">
      <div class="print-list" v-for="(o, i) in options" :key="i">
        <p v-if="o.id === inputValue">{{ o.title }}</p>
      </div>
      <div class="mod-input__radios no-print">
        <label
          :class="{
            'mod-input__radio--disabled': disabled,
          }"
          class="mod-input__radio"
          v-for="(o, i) in options"
          :key="i"
        >
          <input
            type="radio"
            v-model="inputValue"
            :value="o.id"
            class="mod-input__radio-input"
            :disabled="disabled"
          />
          <span class="mod-input__radio-title">{{ o.title }}</span>
        </label>
      </div>
      <span class="mod-input__error" role="alert" v-if="errorMessage">{{ errorMessage }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalRadioComponent",
  props: {
    noPrint: Boolean,
    title: String,
    placeholder: {
      type: String,
      default: "",
    },
    options: Array,
    required: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: [Number, String, Object, Boolean],
    errorMessage: String,
  },
  data() {
    return {
      inputValue: this.value,
    };
  },
  watch: {
    inputValue(e) {
      this.$emit("input", e);
    },
  },
};
</script>
