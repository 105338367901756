<template>
  <span class="sc__map-ui sc__map-label">
    <span>{{ message }}</span>
  </span>
</template>

<script>
export default {
  name: "MapActionLabel",
  props: {
    message: String,
  },
};
</script>

<style lang="stylus">
.sc__map-label {
  display flex
  align-items center
  justify-content center

  span {
    background: var(--color_white);
    border-radius: var(--radius);
    font-weight: 500;
    font-size: 1.125em;
    line-height: 20px;
    text-transform: uppercase;
    color: var(--color_dark);
    padding 14px 24px
    box-shadow: 1px 2px 5px var(--color_black_o1);
  }
}
</style>
