<template>
  <div class="mod">
    <div class="mod__header">
      <span class="mod__indicator mod__indicator--r"></span>
      <span class="mod__title">Сообщить о проблеме</span>
      <div class="mod__header-buttons">
        <button
          v-if="![formLoading, fileLoading].includes(true)"
          @click="close"
          type="button"
          class="mod__close"
        >
          <CloseIcon />
        </button>
      </div>
    </div>
    <div class="mod__body">
      <form class="mod__form">
        <ModalSelectComponent
          title="Категория"
          placeholder="Категория"
          :options="categories || []"
          :required="form.category.required"
          v-model="form.category.value"
          :error-message="form.category.messages[0] || null"
          @input="clearValue"
        />
        <ModalSelectComponent
          title="Проблематика"
          placeholder="Проблематика"
          :options="childCategories || []"
          :required="form.sc_category_id.required"
          v-model="form.sc_category_id.value"
          :error-message="form.sc_category_id.messages[0] || null"
          :allowEmpty="true"
        />
        <ModalTextComponent
          title="Краткое описание"
          placeholder="Краткое описание проблематики"
          :required="form.text.required"
          v-model="form.text.value"
          :error-message="form.text.messages[0] || null"
        />
        <ModalInputLoadingComponent title="Адрес" v-if="addressLoading" />
        <ModalInputComponent
          title="Адрес"
          placeholder="Адрес"
          v-model="form.address.value"
          :required="form.address.required"
          :error-message="form.address.messages[0] || null"
          v-else
        />
        <ModalInputLoadingComponent title="Фото" v-if="fileLoading" />
        <ModalFileComponent
          title="Фото"
          :accept="form.image.accept"
          placeholder="Приложите файл"
          :required="form.image.required"
          :ref="form.image.refName"
          :error-message="form.image.messages[0] || null"
          v-else
        />
        <span class="mod__required-mark">
          <span class="mod-input__required-star">*</span>
          <span> Обязательное поле для заполнения</span>
        </span>
        <label class="mod__checkbox">
          <span class="mod__checkbox-container">
            <input type="checkbox" v-model="form.accepted.value" class="mod__checkbox-input" />
            <span class="mod__checkbox-title">Я согласен с <a href="#">правилами подачи</a> сообщения</span>
          </span>
          <span class="mod__checkbox-message" v-if="form.accepted.messages.length">
            {{ form.accepted.messages[0] || null }}
          </span>
        </label>
        <div class="mod__buttons">
          <button
            class="mod__buttons-accept"
            :class="{
              'mod__buttons-accept--loading': [formLoading, fileLoading].includes(true),
            }"
            type="button"
            @click="submitForm"
          >
            <template v-if="[formLoading, fileLoading].includes(true)">Отправка</template>
            <template v-else>Отправить</template>
          </button>
          <button
            v-if="![formLoading, fileLoading].includes(true)"
            class="mod__buttons-decline"
            type="button"
            @click="close"
          >
            Отменить
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import CloseIcon from "components/svg/CloseIcon.vue";
import AlertModal from "./AlertModal.vue";
import ModalSelectComponent from "components/modals/components/ModalSelectComponent.vue";
import ModalInputComponent from "components/modals/components/ModalInputComponent.vue";
import ModalTextComponent from "components/modals/components/ModalTextComponent.vue";
import ModalFileComponent from "components/modals/components/ModalFileComponent.vue";
import ModalInputLoadingComponent from "components/modals/components/ModalInputLoadingComponent.vue";
import CREATE_CLAIM from "@/graphql/sc/CreateClaim.graphql";
import UPLOAD_IMAGE from "@/graphql/sc/UploadImage.graphql";

export default {
  name: "NewClaimModal",
  props: {
    options: Object,
  },
  data() {
    return {
      form: {
        category: {
          value: null,
          messages: [],
          required: true,
          type: "object",
        },
        sc_category_id: {
          value: null,
          messages: [],
          required: true,
          type: "object",
        },
        text: {
          value: null,
          messages: [],
          required: true,
          type: "string",
        },
        address: {
          value: null,
          messages: [],
          required: true,
          type: "string",
        },
        image: {
          value: null,
          messages: [],
          required: true,
          refName: "image",
          accept: "image/*",
          type: "file",
        },
        accepted: {
          value: null,
          messages: [],
          required: true,
          type: "bool",
        },
      },
      formLoading: false,
      fileLoading: false,
      addressLoading: true,
      fileName: null,
    };
  },
  computed: {
    marker() {
      return this.options.marker;
    },
    categories() {
      return this.$store.getters.socialControlCategories;
    },
    childCategories() {
      if (this.form.category.value) {
        const activeCategory = this.categories.find((c) => c.id === this.form.category.value.id);
        if (activeCategory) {
          return activeCategory.childCategories;
        }
      }
      return [];
    },
  },
  created() {
    window.ymaps.geocode(this.marker).then((res) => {
      const nearest = res.geoObjects.get(0);
      this.form.address.value = nearest.properties.get("name");
      this.addressLoading = false;
    });
  },
  methods: {
    clearValue() {
      this.form.sc_category_id.value = null;
    },
    checkForm() {
      // Очистка
      Object.keys(this.form).forEach((key) => {
        this.form[key].messages = [];
      });
      // Проверка
      Object.keys(this.form).forEach((key) => {
        // Если поля НЕ bool и file
        if (!["file", "bool"].includes(this.form[key].type)) {
          if (this.form[key].required && (this.form[key].value === null || this.form[key].value === "")) {
            this.form[key].messages.push("Поле обязательно для заполнения");
          }
        }
        if (this.form[key].type === "bool") {
          if (this.form[key].required && !this.form[key].value) {
            this.form[key].messages.push("Вы должны нажать на галочку");
          }
        }
        if (this.form[key].type === "file") {
          if (this.form[key].required && this.$refs[this.form[key].refName]) {
            let files = this.$refs[this.form[key].refName].$refs.input.files;
            if (!files.length) {
              this.form[key].messages.push("Выберите файл");
            } else {
              const regExp = /(\w+\/)/;
              const fileType = files[0].type.match(regExp)[0];
              const requiredTypes = this.form[key].accept.match(regExp);
              if (!requiredTypes.includes(fileType)) {
                requiredTypes.forEach((t) => {
                  switch (t) {
                    case "image/":
                      this.form[key].messages.push("Файл должен быть изображением");
                      break;
                    case "video/":
                      this.form[key].messages.push("Файл должен быть в формате видео");
                      break;
                    default:
                      this.form[key].messages.push("Неверный тип файла");
                      break;
                  }
                });
              }
            }
          }
        }
      });
      // Подсчет количества ошибок
      let errorsLength = 0;
      Object.keys(this.form).forEach((key) => {
        errorsLength += this.form[key].messages.length;
      });
      return errorsLength === 0;
    },
    uploadFile() {
      if (!this.fileLoading) {
        this.fileLoading = true;
        return new Promise((resolve) => {
          this.$apollo.provider.clients.sc
            .mutate({
              mutation: UPLOAD_IMAGE,
              variables: {
                files: this.$refs.image.$refs.input.files,
                folder: "sc",
              },
              context: {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("apollo-token"),
                },
              },
            })
            .then(({ data }) => {
              if (data.UploadImage && data.UploadImage.length) {
                this.form.image.value = data.UploadImage[0];
              }
              this.fileLoading = false;
              resolve();
            });
        });
      }
    },
    submitForm() {
      if (this.checkForm()) {
        this.uploadFile().then(() => {
          this.formLoading = true;
          this.$apollo.provider.clients.sc
            .mutate({
              mutation: CREATE_CLAIM,
              variables: {
                sc_category_id: this.form.sc_category_id.value.id,
                address: this.form.address.value,
                x: this.marker[0],
                y: this.marker[1],
                text: this.form.text.value,
                image: {
                  url: this.form.image.value.url,
                  origin_name: this.form.image.value.origin_name,
                  type: this.form.image.value.type,
                  size: this.form.image.value.size,
                },
              },
              context: {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("apollo-token"),
                },
              },
            })
            .then(({ data }) => {
              if (data.CreateClaim) {
                this.$store.dispatch("SC_PAGE", this.$apollo.provider);
                this.$store.state._modals.push({
                  component: AlertModal,
                  options: {
                    message: data.CreateClaim.message,
                  },
                });
                this.close();
              }
              this.formLoading = false;
            })
            .catch(({ graphQLErrors }) => {
              graphQLErrors.forEach((err) => {
                if (err.extensions.category === "validation") {
                  Object.keys(err.extensions.validation).forEach((key) => {
                    err.extensions.validation[key].forEach((message) => {
                      if (this.form[key]) {
                        this.form[key].messages.push(message);
                      }
                    });
                  });
                }
              });
            });
        });
      }
    },
    close() {
      this.options.callback();
      this.$emit("close");
    },
  },
  watch: {
    "form.category.value"() {
      this.form.sc_category_id.value = null;
    },
  },
  components: {
    ModalInputLoadingComponent,
    ModalFileComponent,
    ModalTextComponent,
    ModalInputComponent,
    ModalSelectComponent,
    CloseIcon,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/parts/mod-input.styl"
</style>
