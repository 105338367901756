<template>
  <a
    class="map-modal sc__map-hint sc__map-ui"
    @mouseenter="$emit('mouseenter', $event)"
    @mouseleave="$emit('mouseleave', $event)"
    @click.prevent="$emit('click', marker)"
    href="#"
  >
    <div class="map-modal__header">
      <i class="map-modal__header-icon" v-html="marker.iconSvg"></i>
      <span class="map-modal__title">
        Сообщение #{{ marker.id }} от {{ marker.created_at | formatDate }} г.
      </span>
    </div>
    <div class="map-modal__hint">
      <img :src="marker.head_img | image($store.state.api)" alt="Проблема" class="map-modal__hint-image" />
      <span class="map-modal__hint-gradient"></span>
      <span class="map-modal__hint-text">
        {{ marker.text }}
      </span>
    </div>
  </a>
</template>

<script>
export default {
  name: "ClaimHint",
  props: {
    marker: Object,
  },
};
</script>
