<template>
  <figure class="icon">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.9998 10C18.791 10 19.5643 9.76541 20.2221 9.32588C20.8799 8.88635 21.3926 8.26164 21.6954 7.53074C21.9981 6.79983 22.0773 5.99556 21.923 5.21964C21.7686 4.44372 21.3877 3.73098 20.8283 3.17157C20.2689 2.61216 19.5561 2.2312 18.7802 2.07686C18.0043 1.92252 17.2 2.00173 16.4691 2.30448C15.7382 2.60723 15.1135 3.11992 14.674 3.77772C14.2344 4.43552 13.9998 5.20888 13.9998 6C13.9998 7.06087 14.4213 8.07828 15.1714 8.82843C15.9216 9.57857 16.939 10 17.9998 10ZM17.9998 4C18.3954 4 18.7821 4.1173 19.111 4.33706C19.4399 4.55683 19.6962 4.86918 19.8476 5.23463C19.999 5.60009 20.0386 6.00222 19.9614 6.39018C19.8842 6.77814 19.6938 7.13451 19.4141 7.41422C19.1344 7.69392 18.778 7.8844 18.39 7.96157C18.0021 8.03874 17.5999 7.99914 17.2345 7.84776C16.869 7.69639 16.5567 7.44004 16.3369 7.11114C16.1171 6.78224 15.9998 6.39556 15.9998 6C15.9998 5.46957 16.2106 4.96086 16.5856 4.58579C16.9607 4.21072 17.4694 4 17.9998 4ZM16.8498 12.47C16.7602 12.3253 16.635 12.2059 16.4863 12.1231C16.3375 12.0402 16.1701 11.9968 15.9998 11.9968C15.8296 11.9968 15.6622 12.0402 15.5134 12.1231C15.3647 12.2059 15.2395 12.3253 15.1498 12.47L14.1498 14.1L10.8598 8.5C10.7713 8.35078 10.6455 8.22716 10.4947 8.1413C10.3439 8.05544 10.1734 8.0103 9.99984 8.0103C9.82633 8.0103 9.6558 8.05544 9.50502 8.1413C9.35424 8.22716 9.22839 8.35078 9.13984 8.5L2.13984 20.5C2.05233 20.6516 2.00613 20.8235 2.00586 20.9985C2.00559 21.1735 2.05127 21.3456 2.13833 21.4974C2.22539 21.6492 2.35077 21.7756 2.50195 21.8638C2.65314 21.952 2.82482 21.9989 2.99984 22H20.9998C21.1785 22.0004 21.354 21.9528 21.5081 21.8624C21.6622 21.7719 21.7892 21.6418 21.876 21.4856C21.9628 21.3295 22.0061 21.1529 22.0015 20.9743C21.9969 20.7956 21.9445 20.6215 21.8498 20.47L16.8498 12.47ZM10.4498 20H4.73984L9.99984 11L12.9398 16L11.6898 18L10.4498 20ZM12.7998 20L14.2898 17.63L14.9998 16.57L15.9998 14.89L19.1998 20H12.7998Z"
        fill="black"
      />
    </svg>
  </figure>
</template>

<script>
export default {
  name: "SatelliteViewIcon",
};
</script>
