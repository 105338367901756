<template>
  <div class="sc__map-controls sc__map-ui">
    <transition name="slide-right">
      <div class="sc__map-types" v-if="showMapTypeSelect">
        <button
          class="sc__map-type"
          :class="{ 'sc__map-type--active': activeService === 0 && !showSatellite }"
          type="button"
          @click="$emit('setService', { service: 0, satellite: false })"
        >
          <YandexIcon />
          <span>Яндекс</span>
        </button>
        <button
          class="sc__map-type"
          :class="{ 'sc__map-type--active': activeService === 0 && showSatellite }"
          type="button"
          @click="$emit('setService', { service: 0, satellite: true })"
        >
          <SatelliteViewIcon />
          <span>Спутник</span>
        </button>
      </div>
    </transition>
    <div class="sc__map-buttons">
      <button class="sc__map-button" type="button" @click="$emit('decZoom')">
        <RemoveIcon />
      </button>
      <button class="sc__map-button" type="button" @click="$emit('incZoom')">
        <AddIcon />
      </button>
      <button
        class="sc__map-button"
        :class="{ 'sc__map-button--active': showMapTypeSelect }"
        type="button"
        @click="showMapTypeSelect = !showMapTypeSelect"
      >
        <GlobeIcon />
      </button>
    </div>
  </div>
</template>
<script>
import YandexIcon from "components/svg/Yandex.vue";
import TwoGisIcon from "components/svg/TwoGis.vue";
import SatelliteViewIcon from "components/svg/SatelliteIcon.vue";
import RemoveIcon from "components/svg/RemoveIcon.vue";
import AddIcon from "components/svg/AddIcon.vue";
import GlobeIcon from "components/svg/GlobeIcon.vue";

export default {
  name: "MapTypeControls",
  props: {
    showSatellite: Boolean,
    activeService: Number,
  },
  data() {
    return {
      showMapTypeSelect: false,
    };
  },
  components: { GlobeIcon, AddIcon, RemoveIcon, SatelliteViewIcon, TwoGisIcon, YandexIcon },
};
</script>
