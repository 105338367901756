<template>
  <div class="mod">
    <div class="mod__header">
      <Logo class="mod__icon" />
      <span class="mod__title">{{ options.title }}</span>
      <div class="mod__header-buttons">
        <button @click="$emit('close')" type="button" class="mod__close">
          <CloseIcon />
        </button>
      </div>
    </div>
    <img :src="options.image" class="mod__image" :alt="options.title" />
  </div>
</template>

<script>
import CloseIcon from "components/svg/CloseIcon.vue";
import Logo from "components/svg/Logo.vue";

export default {
  name: "ImageModal",
  props: {
    options: {
      type: Object,
    },
  },
  components: { Logo, CloseIcon },
};
</script>
