<template>
  <label
    class="mod-input"
    :class="{
      'mod-input--error': errorMessage,
    }"
  >
    <span class="mod-input__title">
      <span class="mod-input__required-star" v-if="required">*</span>
      <span> {{ title }}</span>
    </span>
    <div class="mod-input__container">
      <input type="file" class="mod-input__field" :accept="accept" ref="input" />
      <span class="mod-input__error" role="alert" v-if="errorMessage">{{ errorMessage }}</span>
    </div>
  </label>
</template>

<script>
export default {
  name: "ModalFileComponent",
  props: {
    title: String,
    accept: String,
    placeholder: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: true,
    },
    value: [Number, String, Object],
    errorMessage: String,
  },
  data() {
    return {
      inputValue: this.value,
    };
  },
  watch: {
    inputValue(e) {
      this.$emit("input", e);
    },
  },
};
</script>
