<template>
  <label
    class="mod-input"
    :class="{
      'mod-input--error': errorMessage,
    }"
  >
    <span class="mod-input__title">
      <span class="mod-input__required-star" v-if="required">*</span>
      <span> {{ title }}</span>
    </span>
    <div class="mod-input__container">
      <input :type="type" :placeholder="placeholder" v-model="inputValue" class="mod-input__field" />
      <span class="mod-input__error" role="alert" v-if="errorMessage">{{ errorMessage }}</span>
    </div>
  </label>
</template>

<script>
export default {
  name: "ModalInputComponent",
  props: {
    title: String,
    placeholder: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    required: {
      type: Boolean,
      default: true,
    },
    value: [Number, String],
    errorMessage: String,
  },
  data() {
    return {
      inputValue: this.value,
    };
  },
  watch: {
    inputValue(e) {
      this.$emit("input", e);
    },
  },
};
</script>
