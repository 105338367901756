<template>
  <div class="mod">
    <div class="mod__header">
      <span
        class="mod__indicator no-print"
        :class="{
          'mod__indicator--y': marker.status.code === 1,
          'mod__indicator--r': marker.status.code === 2,
          'mod__indicator--g': marker.status.code === 3,
          'mod__indicator--b': marker.status.code === 4,
        }"
      ></span>
      <span class="mod__title"> Сообщение #{{ marker.id }} от {{ marker.created_at | formatDate }} г. </span>
      <div class="mod__header-buttons no-print">
        <button type="button" class="mod__close" @click="print">
          <PrintIcon />
        </button>
        <button @click="$emit('close')" type="button" class="mod__close">
          <CloseIcon />
        </button>
      </div>
    </div>
    <div class="mod__body">
      <form class="mod__form">
        <div class="mod-field">
          <div class="mod-field__title">Категория</div>
          <div class="mod-field__container">{{ marker.category.parent.title }}</div>
        </div>
        <div class="mod-field">
          <div class="mod-field__title">Проблематика</div>
          <div class="mod-field__container">{{ marker.category.title }}</div>
        </div>
        <div class="mod-field">
          <div class="mod-field__title">Краткое описание</div>
          <div class="mod-field__container">{{ marker.text }}</div>
        </div>
        <div class="mod-field">
          <div class="mod-field__title">Адрес</div>
          <div class="mod-field__container">{{ marker.address }}</div>
        </div>
        <div class="mod-field" v-if="marker.head_img" id="lightgallery">
          <div class="mod-field__title">Фото</div>
          <img
            :src="marker.head_img | image($store.state.api)"
            @click="showImage(marker.head_img, 'Фото')"
            alt="Фото"
            class="mod-field__image"
          />
        </div>
        <template v-if="marker.status.code !== 1">
          <div class="mod-field__divider-title">Ход исполнения</div>
          <div class="mod-field" v-if="marker.department">
            <div class="mod-field__title">Ответственное ведомство</div>
            <div class="mod-field__container">{{ marker.department.title }}</div>
          </div>
          <template v-if="marker.department.department_info">
            <div class="mod-field" v-if="marker.department">
              <div class="mod-field__title">Контакты</div>
              <div class="mod-field__container">
                {{ marker.department.department_info.phone || "Номер не указан" }}
              </div>
            </div>
            <div class="mod-field" v-if="marker.department">
              <div class="mod-field__title">Эл.почта</div>
              <div class="mod-field__container">
                {{ marker.department.department_info.email || "Почта не указана" }}
              </div>
            </div>
          </template>
          <div class="mod-field" v-if="marker.deadline_date">
            <div class="mod-field__title">Срок исполнения</div>
            <div class="mod-field__container">
              {{ marker.deadline_date | formatDate }}
            </div>
          </div>
          <div class="mod-field" v-if="marker.status">
            <div class="mod-field__title">Статус</div>
            <div
              class="mod-field__container"
              :class="{
                'mod-field__container--y': marker.status.code === 1,
                'mod-field__container--r': marker.status.code === 2,
                'mod-field__container--g': marker.status.code === 3,
                'mod-field__container--b': marker.status.code === 4,
              }"
            >
              {{ marker.status.name }}
            </div>
          </div>
        </template>
        <template v-if="![1, 2].includes(marker.status.code)">
          <div class="mod-field__divider-title">Результат</div>
          <div class="mod-field" v-if="marker.report_img">
            <div class="mod-field__title">Фото-отчет</div>
            <img
              :src="marker.report_img | image($store.state.api)"
              @click="showImage(marker.report_img, 'Фото-отчет')"
              alt="Фото"
              class="mod-field__image"
            />
          </div>
          <div class="mod-field" v-if="marker.report_document">
            <div class="mod-field__title">Файл-отчет</div>
            <a
              :href="$store.state.api + marker.report_document.document.url"
              :download="marker.report_document.document.title"
              class="mod-field__doc"
            >
              <MapModalDocumentIcon />
              <div class="mod-field__doc-content">
                <span class="mod-field__doc-title">{{ marker.report_document.document.title }}</span>
                <span class="mod-field__doc-subtitle">
                  {{ marker.report_document.document.type }}
                  ({{ marker.report_document.document.size }})
                </span>
              </div>
            </a>
          </div>
        </template>
        <template v-if="[3, 4].includes(marker.status.code)">
          <div class="mod-field__divider-title">Оценка заявителя</div>
          <div class="mod-field" v-if="marker.user_comment">
            <div class="mod-field__title">Отзыв о выполненной работе</div>
            <div class="mod-field__container">{{ marker.user_comment }}</div>
          </div>
          <ModalTextComponent
            title="Отзыв о выполненной работе"
            placeholder="Ваш комментарий"
            :required="form.user_comment.required"
            :error-message="form.user_comment.messages[0] || null"
            v-else
            v-model="form.user_comment.value"
            noPrint
          />
          <ModalRadioComponent
            noPrint
            v-if="marker.is_accepted === null"
            title="Оценка результата"
            v-model="form.is_accepted.value"
            :required="form.is_accepted.required"
            :error-message="form.is_accepted.messages[0] || null"
            :options="isAcceptedOptions"
          />
          <ModalRadioComponent
            v-else
            title="Оценка результата"
            v-model="marker.is_accepted"
            :required="false"
            :options="isAcceptedOptions"
            disabled
          />
          <template v-if="marker.commented_at === null">
            <span class="mod__required-mark no-print">
              <span class="mod-input__required-star">*</span>
              <span> Обязательное поле для заполнения</span>
            </span>
            <div class="mod__buttons no-print">
              <button
                class="mod__buttons-accept"
                :class="{
                  'mod__buttons-accept--loading': commentLoading,
                }"
                type="button"
                @click="submitForm"
              >
                <template v-if="commentLoading">Отправка</template>
                <template v-else>Отправить</template>
              </button>
            </div>
          </template>
        </template>
      </form>
    </div>
  </div>
</template>

<script>
import CloseIcon from "components/svg/CloseIcon.vue";
import COMMENT_CLAIM from "@/graphql/sc/CommentClaim.graphql";
import PrintIcon from "components/svg/PrintIcon.vue";
import MapModalDocumentIcon from "components/svg/MapModalDocumentIcon.vue";
import ModalTextComponent from "components/modals/components/ModalTextComponent.vue";
import ModalRadioComponent from "components/modals/components/ModalRadioComponent.vue";
import AlertModal from "./AlertModal.vue";
import ImageModal from "./ImageModal.vue";

export default {
  name: "ClaimModal",
  props: {
    options: {
      type: Object,
    },
  },
  data() {
    return {
      commentLoading: false,
      isAcceptedOptions: [
        {
          id: true,
          title: "Удовлетворительно",
        },
        {
          id: false,
          title: "Неудовлетворительно",
        },
      ],
      form: {
        user_comment: {
          value: null,
          messages: [],
          required: true,
          type: "string",
        },
        is_accepted: {
          value: true,
          messages: [],
          required: true,
          type: "bool",
        },
      },
      psOptions: {
        wheelPropagation: false,
      },
    };
  },
  computed: {
    marker() {
      return this.options.marker;
    },
  },
  methods: {
    print() {
      let modal = this.$el;
      // let bounds = modal.getBoundingClientRect();
      let specs = ["width=auto", "height=auto"];
      specs = specs.length ? specs.join(",") : "";
      const win = window.open("", "", specs);

      win.document.write(`
        <html lang="ru">
          <head></head>
          <body>
            ${modal.innerHTML}
          </body>
        </html>
      `);
      let styles = document.head.getElementsByTagName("style");
      for (let style of styles) {
        win.document.getElementsByTagName("head")[0].appendChild(style.cloneNode(true));
      }
      let links = document.head.getElementsByTagName("link");
      let promises = [];
      for (let link of links) {
        promises.push(
          new Promise((resolve) => {
            fetch(link.href)
              .then((response) => {
                return response.text();
              })
              .then((data) => {
                let style = document.createElement("style");
                style.innerText = data;
                win.document.getElementsByTagName("head")[0].appendChild(style);
                resolve();
              });
          })
        );
      }
      if (promises.length) {
        Promise.all(promises).then(() => {
          win.focus();
          win.print();
          win.close();
        });
      } else {
        win.focus();
        win.print();
        win.close();
      }
    },
    showImage(image, title) {
      this.$store.state._modals.push({
        component: ImageModal,
        options: {
          image: this.$options.filters.image(image, this.$store.state.api),
          title: title,
        },
      });
    },
    checkForm() {
      // Очистка
      Object.keys(this.form).forEach((key) => {
        this.form[key].messages = [];
      });
      // Проверка
      Object.keys(this.form).forEach((key) => {
        // Если поля НЕ bool и file
        if (!["file", "bool"].includes(this.form[key].type)) {
          if (this.form[key].required && (this.form[key].value === null || this.form[key].value === "")) {
            this.form[key].messages.push("Поле обязательно для заполнения");
          }
        }
        if (this.form[key].type === "bool") {
          if (this.form[key].value === null) {
            this.form[key].messages.push("Вы должны выбрать один из вариантов");
          }
        }
      });
      // Подсчет количества ошибок
      let errorsLength = 0;
      Object.keys(this.form).forEach((key) => {
        errorsLength += this.form[key].messages.length;
      });
      return errorsLength === 0;
    },
    submitForm() {
      if (this.checkForm()) {
        this.commentLoading = true;
        this.$apollo.provider.clients.sc
          .mutate({
            mutation: COMMENT_CLAIM,
            variables: {
              id: this.marker.id,
              user_comment: this.form.user_comment.value,
              is_accepted: this.form.is_accepted.value,
            },
            context: {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("apollo-token"),
              },
            },
          })
          .then(({ data }) => {
            if (data.CommentClaim) {
              this.$store.dispatch("SC_PAGE", this.$apollo.provider);
              this.$store.state._modals.push({
                component: AlertModal,
                options: {
                  message: data.CommentClaim.message,
                },
              });
              this.$emit("close");
            }
            this.commentLoading = false;
          })
          .catch(({ graphQLErrors }) => {
            graphQLErrors.forEach((err) => {
              if (err.extensions.category === "validation") {
                Object.keys(err.extensions.validation).forEach((key) => {
                  err.extensions.validation[key].forEach((message) => {
                    if (this.form[key]) {
                      this.form[key].messages.push(message);
                    }
                  });
                });
              }
            });
          });
      }
    },
  },
  components: { ModalRadioComponent, ModalTextComponent, MapModalDocumentIcon, PrintIcon, CloseIcon },
};
</script>
