<template>
  <label class="mod-input mod-input--loading">
    <span class="mod-input__title">
      <span class="mod-input__required-star" v-if="required">*</span>
      <span> {{ title }}</span>
    </span>
    <span class="mod-input__container">Загрузка</span>
  </label>
</template>

<script>
export default {
  name: "ModalInputLoadingComponent",
  props: {
    title: String,
    required: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
