<template>
  <div class="sc__map-ui sc__map-filter" :class="{ active: msShow }" v-if="statuses.length">
    <div class="map-ms map-ms--open" v-if="mapState === 'map:default'">
      <div class="map-ms__title">
        <span>Все сообщения: {{ sumOfClaims }}</span>
      </div>
      <div class="map-ms__list map-ms__list--for-status">
        <label class="map-ms__item map-ms__item--for-status" v-for="(s, i) in statuses" :key="i">
          <span class="map-ms__check">
            <CheckIcon v-if="selected.includes(s.code)" />
          </span>
          <input
            type="checkbox"
            v-model="selected"
            @change="$emit('change', selected)"
            :value="s.code"
            v-show="false"
          />
          <span class="map-ms__item-title">{{ s.name }}</span>
          <span
            class="map-ms__item-count map-ms__item-count--color"
            :class="{
              'map-ms__item-count--color-y': s.code === 1,
              'map-ms__item-count--color-r': s.code === 2,
              'map-ms__item-count--color-g': s.code === 3,
              'map-ms__item-count--color-b': s.code === 4,
            }"
          >
            {{ s.claims_count }}
          </span>
        </label>
      </div>
    </div>
    <button
      v-if="$store.state.user"
      type="button"
      class="sc__map-report"
      @click="$emit(mapState !== 'map:default' ? 'defaultState' : 'reportState')"
    >
      <EditIcon />
      <span>
        {{ mapState !== "map:default" ? "Отменить" : "Сообщить о проблеме" }}
      </span>
    </button>
  </div>
</template>

<script>
import CheckIcon from "components/svg/CheckIcon.vue";
import EditIcon from "components/svg/EditIcon.vue";

export default {
  name: "MapMarkerFilters",
  props: {
    mapState: String,
    msShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected: [],
    };
  },
  computed: {
    sumOfClaims() {
      return this.statuses.map((s) => s.claims_count).reduce((a, b) => a + b, 0);
    },
    statuses() {
      return this.$store.getters.socialControlStatuses;
    },
  },
  watch: {
    statuses() {
      this.selectAll();
    },
  },
  methods: {
    selectAll() {
      this.selected = this.statuses.map((c) => c.code);
      this.$emit("change", this.selected);
    },
  },
  components: { EditIcon, CheckIcon },
};
</script>
